<template>
    <div>
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p class="text-center text-sm font-semibold text-gray-500 tracking-wide">
                Built by the people behind 
                <a href="https://www.metricrule.com/" target="_blank" class="font-semibold text-indigo-900"> 📏 Metricrule</a>
                based on Google's 
                <a href="https://research.google/pubs/pub46555/" target="_blank" class="font-semibold text-indigo-900">
                ML Test Score
                </a>.
            </p>
        </div>
    </div>
</template>